import React, {Component} from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Typography,
    notification,
    DatePicker
} from "antd";
import api from "../../api";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import moment from "moment";
import {SearchOutlined} from "@ant-design/icons";
import { CSVLink } from "react-csv";
const {Title} = Typography;



class SalesTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sales:{},
            loading:true,
            filters:{pageSize:100},
            pageSize:100,
        }
    }


    componentDidMount() {
        this.setState({
            loading:true
        })
        fetch(api.Sales.url+`?year=${moment().year()}&month=${(moment().month()+1)}&start=0&pageSize=100`, {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Sales.method,
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(response => {
                        this.setState({
                            sales: response,
                            loading: false,
                        })
                    })
                } else if (response.status === 401) {
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    this.setState({
                        loading: false,
                    })
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            })
    }

    searchSales = (params) => {
        let searchData= {...this.state.filters,...params}
        if(searchData){
            Object.keys(searchData).forEach(key => searchData[key] === undefined && delete searchData[key])
        }
        let year;
        let month;
        let start;
        if(searchData.date){
            year = searchData.date.format('YYYY')
            month = searchData.date.format('MM')
        }else{
            year = moment().year()
            month = moment().month()+1
        }
        if(params.start){
            start= searchData.start
        }else if(params.current){
            start = (searchData.current-1)*searchData.pageSize
        }else{
            start = 0
        }

        this.setState({
            loading: true,
            csvData:false,
        });

       return fetch(api.Sales.url+`?year=${year}&month=${month}&start=${start}`+(searchData.pageSize?'&pageSize='+searchData.pageSize:''), {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Sales.method,
        })
            .then(response => response.json())
            .then((response) => {
                this.setState({
                    sales:response,
                    filters:{...this.state.filters,...searchData},
                    loading:false,
                })
                return response
        })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            });
    }
    expandableContent = (content) => {
        const cols = [
            {
                title: 'Name',
                dataIndex: 'productName',
                key: 'productName',
            },
            {
                title: 'Track Owner',
                dataIndex: 'trackOwner',
                key: 'trackOwner',
            },
            {
                title: 'Product Type',
                dataIndex: 'licenseType',
                key: 'licenseType',
            },
            {
                title: 'Genres',
                dataIndex: 'genres',
                key: 'genres',
            },
            {
                title: 'Coupon',
                dataIndex: 'coupon',
                key: 'coupon',
            },
            {
                title: 'Total',
                dataIndex: 'orderItemTotal',
                key: 'orderItemTotal',
                align:'center',
                render:price=>parseFloat(price.toFixed(2))
            },
            {
                title: 'Actual Sale',
                dataIndex: 'actualSale',
                key: 'actualSale',
                align:'center',
                render:price=>parseFloat(price.toFixed(2))
            },
            {
                title: 'Transaction fee',
                dataIndex: 'transactionFee',
                align:'center',
                key: 'transactionFee',
                render:price=>parseFloat(price.toFixed(2))
            },
            {
                title: 'RS',
                dataIndex: 'rs',
                align:'center',
                key: 'rs',
                render:price=>parseFloat(price.toFixed(2))
            },
            {
                title: 'TO',
                dataIndex: 'to',
                align:'center',
                key: 'to',
                render:price=>parseFloat(price.toFixed(2))
            },
        ]
        return  <Table pagination={false} title={() => <h4>Products</h4>}
                    dataSource={content.items}
                   loading={this.state.loading} rowKey={record => record.title} columns={cols}
                   bordered/>

    }
    handleCsvGenerator =()=>{
        let month;
        let year;
        this.setState({
            loading:true
        })
        if(this.state.filters.date){
            month = this.state.filters.date.utc().month()+1
            year = this.state.filters.date.utc().year()
        }else{
            month = moment().month()+1
            year = moment().year()
        }
        let csvData = [['Date','User','Product Name','Track Owner','Product Type','Genres','Coupon','Order item Total','Actual Sale','Transaction fee','RS','TO']]
         this.searchSales({...this.state.filters,start:0,current:1,pageSize:5000}).then(sales=>{

            sales.results.forEach(singleBuy=>{
                 singleBuy.items.forEach(singleItem=>{
                     csvData.push(Object.values({date:moment(singleBuy.date).format('YYYY-MM-DD HH:mm:ss'),user:singleBuy.user,...singleItem}))
                 })
             })
             this.setState({
                 csvData: {
                     title:`sales report_${year}_${month}.csv`,
                     data:csvData,
                     loading:false
                 }
             },()=>{
                 setTimeout(()=>document.getElementById('csvFile').click(),1000)
             })
         })

    }
    render() {
        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
                key: 'date',
                width:200
            },
            {
                title: 'User',
                dataIndex: 'user',
                sorter: (a, b) => a.user.localeCompare(b.user),
                key: 'user',
            },
            {
                title: 'Products count',
                dataIndex: 'count',
                align:'center',
                sorter: (a, b) => a.items.length-b.items.length,
                key: 'count',
                render:(item,record)=>record.items.length
            },
            {
                title: 'Total Price',
                dataIndex: 'toprice',
                align:'center',
                key: 'toprice',
                render:(item,record)=> {
                    let price = 0;
                    record.items.forEach(item=>{
                        price = price + item.orderItemTotal
                    })
                    return parseFloat(price.toFixed(2))
                }
            },
            {
                title: 'Total Actual Sale',
                dataIndex: 'acsale',
                align:'center',
                key: 'acsale',
                render:(item,record)=> {
                    let price = 0;
                    record.items.forEach(item=>{
                        price = price + item.actualSale
                    })
                    return parseFloat(price.toFixed(2))
                }
            },
            {
                title: 'Total Transaction Fees',
                dataIndex: 'TTfs',
                align:'center',
                key: 'TTfs',
                render:(item,record)=> {
                    let price = 0;
                    record.items.forEach(item=>{
                        price = price + item.transactionFee
                    })
                    return parseFloat(price.toFixed(2))
                }
            },
            {
                title: 'Total RS',
                dataIndex: 'tors',
                align:'center',
                key: 'tors',
                render:(item,record)=> {
                    let price = 0;
                    record.items.forEach(item=>{
                        price = price + item.rs
                    })
                    return parseFloat(price.toFixed(2))
                }
            },
            {
                title: 'Total TO',
                dataIndex: 'toto',
                align:'center',
                key: 'toto',
                render:(item,record)=> {
                    let price = 0;
                    record.items.forEach(item=>{
                        price = price + item.to
                    })
                    return parseFloat(price.toFixed(2))
                }
            },
        ];
        return (
            <Row className={'tab-content'}>
                <Col lg={24}>

                    <Form  layout={'horizontal'} onFinish={this.searchSales}>
                        <Row className={'filters-row'}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>Basic filters</Title>
                            </Col>
                            <Col>
                                <Form.Item  initialValue={moment(moment().year()+'/'+(moment().month()+1), 'YYYY/MM')} name={'date'}>
                                    <DatePicker  format={'YYYY/MM'} picker="month" />
                                </Form.Item>
                            </Col>
                            <Col style={{marginLeft:'7px',marginRight:'15px'}}>
                                <Button htmlType={'submit'} type="primary"><SearchOutlined />Search</Button>
                            </Col>
                            <Col>
                                <Button onClick={this.handleCsvGenerator} loading={this.state.loading}>Generate CSV</Button>
                                {this.state.csvData?<CSVLink style={{display:'none'}} id={'csvFile'} filename={this.state.csvData.title}  asyncOnClick={true} data={this.state.csvData.data}/>:null}
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col  lg={24}>
                    <Table dataSource={this.state.sales.results?this.state.sales.results:[]} rowKey={(record,key) => key} columns={columns} loading={this.state.loading} size={"small"}
                           expandable={{
                               expandedRowRender: record => this.expandableContent(record),
                               rowExpandable: record => record.items.length,
                           }}

                           onChange={(pagination)=>this.searchSales({...this.state.filters,...pagination})}
                           pagination={{
                               defaultPageSize: this.state.filters.pageSize?this.state.filters.pageSize:100,
                               total:this.state.sales.pagination&&this.state.sales.pagination.totalCount?this.state.sales.pagination.totalCount:0,
                               current: this.state.sales.pagination&&this.state.sales.pagination.nextStart?Math.ceil(this.state.sales.pagination.nextStart / (this.state.filters.pageSize?this.state.filters.pageSize:100)): this.state.sales.pagination&&!this.state.sales.pagination.nextStart?Math.ceil(this.state.sales.pagination.totalCount / (this.state.filters.pageSize?this.state.filters.pageSize:100)):1
                           }}
                    />

                </Col>
            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    }))(withRouter(SalesTab));
