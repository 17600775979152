const endpoint = process.env.REACT_APP_API_ENDPOINT;
const api = {
    Login: {
        url: `${endpoint}user/login`,
        method: "POST"
    },
    Dashboard: {
        url: `${endpoint}dashboard`,
        method: "GET"
    },
    Categories:{
        GET:{
            url: `${endpoint}search/categories`,
            method: "GET"
        },
        Sounds:{
            url: `${endpoint}category/addsoundslike`,
            method: "PUT"
        },
        Genre:{
            url: `${endpoint}category/addgenre`,
            method: "PUT"
        },
    },
    Sales:{
        url: `${endpoint}sales`,
        method: "GET"
    },
    User: {
        Create: {
            url: `${endpoint}user`,
            method: "POST"
        },
        Update: {
            url: `${endpoint}user/`,
            method: "PUT"
        },
        Search: {
            url: `${endpoint}user/search`,
            method: "GET"
        },
        UpdatePassword: {
            url: `${endpoint}user/updatepassword`,
            method: "PUT"
        },
        Subscriptions: {
            url: `${endpoint}VendorSubsription/`,
            method: "GET"
        },
        SubscriptionStatus: {
            url: `${endpoint}VendorSubsription/status/`,
            method: "GET"
        },
        SubscriptionTransaction: {
            url: `${endpoint}VendorSubsription/transactions/`,
            method: "GET"
        },
        getToken: {
            url: `${endpoint}user/accesstoken/`,
            method: "GET"
        }
    },
    Applicant:{
        Search:{
            url: `${endpoint}applicant/search`,
            method: "GET"
        },
        Approv:{
            url: `${endpoint}applicant/approve/`,
            method: "PUT"
        },
        Reject:{
            url: `${endpoint}applicant/reject/`,
            method: "PUT"
        },

    },
    Song: {
        Update: {
            url: `${endpoint}product/updatetrack`,
            method: "PUT",
            urlRating: `${endpoint}product/setrating`
        },
        Search: {
            url: `${endpoint}song/search`,
            method: "GET"
        },
        Categories:{
            url: `${endpoint}search/categories`,
            method: "GET"
        },
        Product:{
            url: `${endpoint}product/`,
            method: "PUT"
        },
        MakeFeatured:{
            url: `${endpoint}product/featuresong/`,
            method: "PUT"
        },
        Remove:{
            url: `${endpoint}product/deleteproductitem/`,
            method: "DELETE"
        },
        GetDownload:{
            url: `${endpoint}song/`,
            method: "GET"
        }
    },
    Affiliate: {
        Get: {
            url: `${endpoint}affilate/`,
            method: "GET"
        },
        Create:{
            url: `${endpoint}affilate/`,
            method: "POST"
        },
    },
    CanceledApplicants:{
        Get: {
            url: `${endpoint}applicant/SearchDeclined`,
            method: "GET"
        },
    },
    Coupon: {
        Get: {
            url: `${endpoint}Coupon/`,
            method: "GET"
        },
        Update: {
            url: `${endpoint}coupon/update`,
            method: "PUT"
        },
        Create:{
            url: `${endpoint}coupon/add`,
            method: "POST"
        },
        Product:{
            url: `${endpoint}product/`,
            method: "PUT"
        }
    }

}
export default api;
